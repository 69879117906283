import React, { useEffect } from "react";
import Layout from "../../components/_App/layout";
import SEO from "../../components/_App/seo";
import Navbar from "../../components/_App/NavbarStyleFour";
import Footer from "../../components/_App/Footer";
import PageBanner from "../../components/Common/PageBanner";
import * as Icon from "react-feather";
import { Link } from "gatsby";
import BlogSidebar from "../../components/Blog/BlogSidebar";
import virtual_lesson from "../../assets/images/blog-image/virtual_lesson.jpg";
import vr from "../../assets/images/blog-image/vr.jpg";
import { CodeBlock, vs2015 } from "react-code-blocks";
import Blog from "../../assets/json/blog.json";

function MyCoolCodeBlock({ code, language, showLineNumbers }) {
    return (
        <CodeBlock
            text={code}
            language={language}
            showLineNumbers={showLineNumbers}
            theme={vs2015}
        />
    );
}

const articleData = {
    id: 34,
    title: "Come Integrare Auth0 con React:",
    desc: "Sicurezza e Comunicazione con il Backend",
    img: "/blog-image/auth0.png",
    page: "blog/react-auth0",
    data: "27 Ott 2023",
    author: "Staff",
};

const BlogDetails = () => {
    const [isMobile, setMobile] = React.useState(false);

    const handleResize = () => {
        if (window.innerWidth < 1275) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <Layout>
            <SEO
                title={articleData.title}
                meta={[
                    {
                        name: `description`,
                        content:
                            "Inutile parlare di quanto la sicurezza e l'accessibilità siano fondamentali per il successo delle applicazioni web e mobili, giusto?",
                    },
                    {
                        property: `og:title`,
                        content: articleData.title + "Appius, software house",
                    },
                ]}
            />

            <Navbar />

            <PageBanner pageTitle={articleData.title} />

            <div className="blog-details-area ptb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="blog-details-desc">
                                <div className="article-image">
                                    <img
                                        src={
                                            require("../../assets/images" +
                                                articleData.img).default
                                        }
                                        alt="image"
                                    />
                                </div>

                                <div className="article-content">
                                    <div className="entry-meta">
                                        <ul>
                                            <li>
                                                <Icon.Clock />{" "}
                                                {articleData.data}
                                            </li>
                                            <li>
                                                <Icon.User />{" "}
                                                <Link to="#">
                                                    {articleData.author}
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>

                                    <p>
                                        Inutile parlare di quanto la sicurezza e
                                        l'accessibilità siano fondamentali per
                                        il successo delle applicazioni web e
                                        mobili, giusto? L'autenticazione e
                                        l'autorizzazione degli utenti sono
                                        processi critici che richiedono una
                                        gestione affidabile. In questo articolo,
                                        esploreremo come integrare Auth0, una
                                        piattaforma di autenticazione che si è
                                        affermata sul mercato, con React, la
                                        nostra libreria JavaScript preferita per
                                        lo sviluppo web e mobile. Vedremo come
                                        questa integrazione può migliorare la
                                        sicurezza e l'esperienza utente delle
                                        tue applicazioni, fornendo una soluzione
                                        solida e affidabile.
                                    </p>

                                    <h3>Perché Auth0?</h3>

                                    <p>
                                        Auth0 è una piattaforma di identità e
                                        autenticazione basata su cloud che
                                        semplifica la gestione dell'accesso
                                        degli utenti alle tue applicazioni. La
                                        sua flessibilità e facilità d'uso lo
                                        rendono una scelta ideale per le aziende
                                        che desiderano implementare una
                                        soluzione di autenticazione scalabile e
                                        sicura.
                                    </p>
                                    <p>
                                        Ecco alcune delle ragioni per cui Auth0
                                        è una scelta vincente:
                                    </p>
                                    <ol>
                                        <li>
                                            <p>
                                                <b>Sicurezza Avanzata</b>:Auth0
                                                offre funzionalità di sicurezza
                                                avanzate come il rilevamento
                                                delle minacce, l'autenticazione
                                                a due fattori (2FA) e
                                                l'autenticazione basata su
                                                criteri per proteggere le tue
                                                applicazioni da attacchi
                                                informatici.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <b>Facilità d'Uso</b>:
                                                L'interfaccia utente intuitiva
                                                di Auth0 semplifica la gestione
                                                degli utenti, delle
                                                autorizzazioni e delle
                                                configurazioni di
                                                autenticazione, risparmiando
                                                tempo prezioso agli
                                                sviluppatori.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <b>Conformità</b>: Auth0 è
                                                conforme a normative come GDPR e
                                                HIPAA, il che significa che puoi
                                                essere sicuro che i dati degli
                                                utenti siano trattati nel
                                                rispetto della privacy e della
                                                sicurezza.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <b>Scalabilità</b>: Auth0 è
                                                progettato per gestire milioni
                                                di utenti e può scalare in base
                                                alle esigenze della tua
                                                applicazione.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <b>
                                                    Supporto per Diverse
                                                    Identità
                                                </b>
                                                : Supporta una vasta gamma di
                                                fornitori di identità, come
                                                Google, Facebook, Microsoft, e
                                                permette agli utenti di accedere
                                                con le loro credenziali
                                                preferite.
                                            </p>
                                        </li>
                                    </ol>
                                    <h3>Integrazione di Auth0 con React</h3>
                                    <p>
                                        Ora che abbiamo compreso le potenzialità
                                        di Auth0, vediamo come possiamo
                                        integrarlo con React per creare un
                                        sistema di autenticazione robusto per le
                                        tue applicazioni.
                                    </p>
                                    <ol>
                                        <li>
                                            <h5>Creare un Account su Auth0</h5>
                                            <p>
                                                La prima cosa da fare è creare
                                                un account su Auth0. Una volta
                                                registrato, potrai configurare
                                                le tue applicazioni e ottenere
                                                le credenziali necessarie per
                                                l'integrazione.
                                            </p>
                                        </li>
                                        <li>
                                            <h5>
                                                Installare il Pacchetto Auth0
                                                React
                                            </h5>
                                            <p>
                                                Per semplificare l'integrazione
                                                di Auth0 con React, puoi
                                                utilizzare il pacchetto
                                                ufficiale{" "}
                                                <b>@auth0/auth0-react</b>. Per
                                                installarlo, esegui il seguente
                                                comando nel tuo progetto React:
                                            </p>
                                            <MyCoolCodeBlock
                                                code={`npm install @auth0/auth0-react`}
                                                language="javascript"
                                                showLineNumbers={true}
                                            />
                                        </li>
                                        <li>
                                            <h5>Configurare Auth0</h5>
                                            <p>
                                                Nel tuo progetto React, crea un
                                                file di configurazione per
                                                Auth0. Qui dovrai inserire le
                                                tue credenziali e le
                                                informazioni specifiche del tuo
                                                tenant Auth0. Un esempio di
                                                configurazione potrebbe apparire
                                                così:
                                            </p>
                                            <MyCoolCodeBlock
                                                code={`// auth0-config.js
const domain = 'YOUR_AUTH0_DOMAIN';
const clientId = 'YOUR_AUTH0_CLIENT_ID';
const audience = 'YOUR_AUTH0_API_AUDIENCE';

export { domain, clientId, audience };`}
                                                language="javascript"
                                                showLineNumbers={true}
                                            />
                                        </li>
                                        <br />
                                        <br />
                                        {isMobile ? (
                                            <>
                                                <div className="d-flex justify-content-center">
                                                    <Link
                                                        to="/l-outsourcing-con-appius"
                                                        className="btn btn-primary"
                                                    >
                                                        Hai bisogno di aiuto per
                                                        il tuo progetto? <br />
                                                        <span
                                                            style={{
                                                                fontSize: 20,
                                                            }}
                                                        >
                                                            Clicca qui
                                                        </span>
                                                    </Link>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="d-flex justify-content-center">
                                                    <Link
                                                        to="/l-outsourcing-con-appius"
                                                        className="btn btn-primary"
                                                    >
                                                        Hai bisogno di aiuto per
                                                        il tuo progetto? Clicca
                                                        qui
                                                    </Link>
                                                </div>
                                            </>
                                        )}
                                        <br />
                                        <li>
                                            <h5>
                                                Implementare l'Autenticazione
                                            </h5>
                                            <p>
                                                Ora puoi iniziare a utilizzare
                                                Auth0 nel tuo progetto React.
                                                Ecco un esempio di come potresti
                                                configurare l'autenticazione in
                                                un componente React:
                                            </p>
                                            <MyCoolCodeBlock
                                                code={`// AuthButton.js
import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

function AuthButton() {
    const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

    return (
        <div>
            {isAuthenticated ? (
                <button onClick={() => logout()}>Logout</button>
            ) : (
                <button onClick={() => loginWithRedirect()}>Login</button>
            )}
        </div>
    );
}

export default AuthButton;`}
                                                language="javascript"
                                                showLineNumbers={true}
                                            />
                                        </li>
                                        <li>
                                            <h5>Proteggere le Route</h5>
                                            <p>
                                                Puoi utilizzare Auth0 per
                                                proteggere le rotte della tua
                                                applicazione in modo che solo
                                                gli utenti autenticati possano
                                                accedervi. Ecco un esempio di
                                                come potresti farlo utilizzando
                                                React Router:
                                            </p>
                                            <MyCoolCodeBlock
                                                code={`// ProtectedRoute.js
import React from 'react';
import { Route } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

function ProtectedRoute({ component: Component, ...rest }) {
    const { isAuthenticated } = useAuth0();

    return (
        <Route
            {...rest}
            render={(props) =>
                isAuthenticated ? <Component {...props} /> : <Redirect to="/login" />
            }
        />
    );
}

export default ProtectedRoute;`}
                                                language="javascript"
                                                showLineNumbers={true}
                                            />
                                        </li>
                                        <li>
                                            <h5>Gestire il Profilo Utente</h5>
                                            <p>
                                                Auth0 fornisce informazioni sul
                                                profilo dell'utente autenticato,
                                                che puoi utilizzare per
                                                personalizzare l'esperienza
                                                utente. Ad esempio, puoi
                                                mostrare il nome utente o
                                                l'immagine del profilo nell'app.
                                            </p>
                                        </li>
                                        <li>
                                            <h5>Token di Accesso</h5>
                                            <p>
                                                Un aspetto fondamentale
                                                dell'autenticazione con Auth0 è
                                                il sistema dei token. Quando un
                                                utente si autentica con
                                                successo, Auth0 fornisce un
                                                token di accesso (JWT) che
                                                contiene informazioni
                                                sull'utente e le autorizzazioni.
                                                Questo token può essere passato
                                                al tuo sistema di backend per
                                                verificare l'identità
                                                dell'utente e concedere
                                                l'accesso alle risorse protette.
                                            </p>
                                            <p>
                                                Per passare il token al backend,
                                                puoi includerlo
                                                nell'intestazione delle
                                                richieste HTTP. Ad esempio,
                                                utilizzando la libreria Axios,
                                                puoi fare qualcosa del genere:
                                            </p>
                                            <MyCoolCodeBlock
                                                code={`import axios from 'axios';

const token = 'IL_TUO_TOKEN';

axios.defaults.headers.common['Authorization'] = 'Bearer $ {token}';

// Ora puoi effettuare richieste al tuo backend con il token incluso nell'intestazione.`}
                                                language="javascript"
                                                showLineNumbers={true}
                                            />
                                            <p>
                                                Il backend può quindi utilizzare
                                                il token per verificare l'utente
                                                e concedere l'accesso alle
                                                risorse protette.
                                            </p>
                                        </li>
                                    </ol>
                                    <p>
                                        L'integrazione di Auth0 con React non
                                        solo migliora la sicurezza e
                                        l'accessibilità delle tue applicazioni,
                                        ma offre anche un sistema completo per
                                        la gestione dell'identità degli utenti.
                                        Con Auth0, hai il controllo completo
                                        sull'autenticazione e
                                        sull'autorizzazione degli utenti,
                                        consentendoti di concentrarti sullo
                                        sviluppo delle funzionalità principali
                                        della tua app.
                                    </p>
                                    <p>
                                        I nostri sviluppatori sono pronti ad
                                        assisterti nell'integrazione di Auth0
                                        nelle tue applicazioni o nella creazione
                                        di nuove soluzioni personalizzate per le
                                        esigenze dei tuoi clienti. Contattaci
                                        oggi stesso per scoprire come possiamo
                                        aiutarti. Buon coding a tutti!
                                    </p>
                                    <br />
                                    <br />
                                    {isMobile ? (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? <br />
                                                    <span
                                                        style={{ fontSize: 20 }}
                                                    >
                                                        Clicca qui
                                                    </span>
                                                </Link>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? Clicca qui
                                                </Link>
                                            </div>
                                        </>
                                    )}
                                    <br />
                                </div>

                                <div
                                    style={{ marginTop: 30 }}
                                    className="startp-post-navigation"
                                >
                                    {Blog.articles
                                        .filter((a) => a.id != articleData.id)
                                        .slice(0, 2)
                                        .map((article) => {
                                            return (
                                                <div className="prev-link-wrapper">
                                                    <div className="info-prev-link-wrapper">
                                                        <Link
                                                            to={
                                                                "/" +
                                                                article.page
                                                            }
                                                        >
                                                            <span className="image-prev">
                                                                <img
                                                                    src={
                                                                        require("../../assets/images" +
                                                                            article.img)
                                                                            .default
                                                                    }
                                                                    alt="image"
                                                                />
                                                                <span className="post-nav-title">
                                                                    VAI
                                                                </span>
                                                            </span>

                                                            <span className="prev-link-info-wrapper">
                                                                <span className="prev-title">
                                                                    {
                                                                        article.title
                                                                    }
                                                                </span>
                                                                <span className="meta-wrapper">
                                                                    <span className="date-post">
                                                                        {
                                                                            article.data
                                                                        }
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12">
                            <BlogSidebar id={articleData.id} />
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </Layout>
    );
};
export default BlogDetails;
